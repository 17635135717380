import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import GlobalStyle from '../../components/GlobalStyle'
import { ThemeProvider } from 'styled-components'
import ECU from '../../themes/ECU'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useSiteSeo } from '../../hooks/useSiteSeo'
import { SEOContext, Seo } from '../../components/Seo'
import CTASlider from '../../components/CTASlider'
import Popup from '../../components/Popup'
import WhatsappImage from '../../../content/assets/svg/whatsapp-brands.svg'
import PhoneImage from '../../../content/assets/svg/phone-solid.svg'
import Helmet from 'react-helmet'
import fetch from 'node-fetch'
import CookieConsentV2 from '../../components/CookieConsentV2'
import PreferenceModal from '../../components/CookieConsentV2/Modal/PreferenceModal'

export const CarStateContext = React.createContext()

const PageLayout = ({ pageData, state, location, children, prevPath }) => {
	const [cookiePreferenceModalOpen, setCookiePreferenceModalOpen] = useState(false)
	const [allDeclined, setAlldeclined] = useState(false)
	const [cookiePreferences, setCookiePreferences] = useState([])

	const siteSeo = useSiteSeo()

	useEffect(async () => {
		// eslint-disable-next-line no-undef
		const brands = await fetch(`${process.env.GATSBY_API_URL}/brands`, {
			method: 'get'
		}).then(res => res.json())

		const currentLocalStorageDumpId = JSON.parse(window.localStorage.getItem('brandValue'))?.dumpid
		const newDumpId = brands[0]?.dumpId

		if (currentLocalStorageDumpId !== newDumpId) {
			localStorage.removeItem('brandValue')
			localStorage.removeItem('modelValue')
			localStorage.removeItem('generationValue')
			localStorage.removeItem('productValue')
		}
	}, [])

	const childrenWithProps = React.Children.map(children, child => {
		// Checking isValidElement is the safe way and avoids a typescript
		// error too.

		if (React.isValidElement(child)) {
			return React.cloneElement(child, { location: location })
		}

		return child
	})

	const openWhatsapp = () => {
		// eslint-disable-next-line no-undef
		window.dataLayer.push({
			event: 'whatsapp-click'
		})
	}

	const closeHandler = () => {
		setCookiePreferenceModalOpen(false)
	}

	const declineAllHandler = () => {
		setAlldeclined(true)
		setCookiePreferenceModalOpen(false)
	}

	const savePrefHandler = preferences => {
		setCookiePreferences(preferences)
		setCookiePreferenceModalOpen(false)
	}

	return (
		<SEOContext.Provider value={{ global: siteSeo }}>
			<ThemeProvider theme={ECU}>
				<CarStateContext.Provider
					value={{
						state: state,
						location: location
					}}
					prevPath={prevPath}
				>
					<Seo post={pageData} title={pageData.seo.title} meta={pageData.seo.metaDesc} />

					<Helmet htmlAttributes={{ lang: 'nl-NL' }}>
						<link rel="alternate" hhrefLang="nl-be" href={`https://ecuadaptions.nl${location?.pathname}`} />
						<script id="mcjs" type="javascript">
							{`
							!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/3bf64ba05c39302b3801a9e34/817f817d48516fb609c96d44c.js");
							`}
						</script>
					</Helmet>
					<Header headerData={pageData.header && pageData.header} isFrontPage={pageData.isFrontPage} />
					<Body>
						<>
							<CookieConsentV2
								openModal={isOpen => setCookiePreferenceModalOpen(isOpen)}
								allDeclined={allDeclined}
								cookiePreferences={cookiePreferences}
							/>
							<PreferenceModal
								closeHandler={closeHandler}
								open={() => cookiePreferenceModalOpen}
								declineAllHandler={() => {
									declineAllHandler()
								}}
								savePrefHandler={preferences => savePrefHandler(preferences)}
							/>
						</>
						<Content>
							<GlobalStyle />
							{childrenWithProps}
						</Content>
						<CTASlidersContainer>
							<CTASlider
								name="phone"
								// position="42%"
								icon={PhoneImage}
								link="tel:0180396341"
								content="+ 31 0 180 396 341"
							/>
							<CTASlider
								name="whatsapp"
								position="70px"
								icon={WhatsappImage}
								click={openWhatsapp}
								link="https://wa.me/31180396341"
								content="+ 31 0 180 396 341"
							/>
						</CTASlidersContainer>
						{/* <Popup /> */}
					</Body>
					<Footer />
				</CarStateContext.Provider>
			</ThemeProvider>
		</SEOContext.Provider>
	)
}

const Body = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Content = styled.div`
	/* width: 100%; */
	margin: 0 10vw;
	max-width: 75rem;

	@media (max-width: ${props => props.theme.sizes.maxDesktop}) {
		margin: 0 5vw;
	}
`

const CTASlidersContainer = styled.div`
	position: absolute;
	top: 45vh;
`

export default PageLayout
